.profile-layout {
  .mat-select-trigger {
    width: 100%;
    height: 4.8rem;
    padding: 0 1rem;
    background: var(--white-color) 0% 0% no-repeat padding-box;
    border: 0.1rem solid #cccccc;
    border-radius: 0.5rem;
    font-size: 1.6rem;
    align-items: center;
    display: flex !important;
    flex-direction: row;
  }

  .mat-select-value {
    max-width: 100% !important;
    width: 100%;
    overflow: visible !important;
  }
}

.dropdown-select {
  .mat-select {
    border: 1px solid var(--input-border-color);
    border-radius: 10px;
    padding: 0.5rem 1rem;
    height: 4.8rem;
    width: 100%;
    flex: 0.58;
    text-align: center;
  }

  .mat-select-trigger {
    height: 100%;
  }

  .mat-select-min-line {
    font-size: var(--fs-20);
    font-weight: 900;
    color: var(--primary-color);
  }

  .mat-select-arrow {
    border-left: 24px solid transparent !important;
    border-right: none !important;
    border-top: 24px solid transparent !important;
    border-image-source: url("./../../assets/images/keyboard_arrow_down_black_24dp.svg") !important;
    border-image-repeat: stretch !important;
    transform: rotate(0deg) !important;
  }

  .mat-select {
    padding: 1rem !important;
  }

  .mat-select-min-line {
    font-size: 1.6rem !important;
    color: #000000 !important;
  }

  .mat-form-field-appearance-legacy,
  .mat-form-field-underline {
    background-color: transparent !important;
  }

  .mat-form-field-infix {
    width: 16.4rem !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  .mat-form-field.mat-focused,
  .mat-form-field-ripple,
  .mat-form-field.mat-focused,
  .mat-form-field-ripple.mat-accent {
    background-color: transparent !important;
  }

  .mat-form-field-underline {
    display: none;
  }
}