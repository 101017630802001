@import "./@material/_reservation.scss";
@import "./@material/home.scss";
@import "./@material/payment-plan.scss";
@import "./@material/developer.scss";
@import "./@material/location.scss";
@import "./@material/prelaunch.scss";
@import "./@material/search-popup.scss";
@import "./@material/developers.scss";
@import "./@material/imageViewer.scss";
@import "./@material/projects";
@import "./@material/compound";
@import "./@material/developers_card.scss";
@import "./@material/compare-payment-plan.scss";
@import "./@material/contact-sales";
@import "./@material/compare-unit-mobile.scss";
@import "./@material/compare-compound-mobile.scss";
@import "./@material/custom-payment-plan.scss";
@import "./@material/_eoi.scss";
@import "./@material/override-material-icon";
@import "./@material/_flag-currency.scss";
@import "./@material/adib";
@import "./@material/appointment";
@import "./@material/developer.scss";
@import "./@material/salesman_profile";
@import "./@material/offers";
@import "./@material/locations-card.scss";
@import "./@material/contact-salesman";
@import "./@material/customer-service";

.mat-form-field-wrapper {
  padding-bottom: 0.5em !important;
}

.mat-expansion-panel-header {
  font-size: 2rem !important;
  font-weight: 700 !important;
  margin: 1rem 0 !important;
}

.mat-dialog-container {
  padding: 0 !important;

  .mat-form-field-infix {
    padding: 0.5rem !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.mat-paginator {
  background: none;
}

.mat-expansion-panel-spacing {
  margin: 0 !important;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
  border-bottom: 1px solid #b1b1b1 !important;
  margin: 0 2rem !important;
  border-radius: 0 !important;
  padding: 0.5rem 0;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]):last-child {
  border-bottom: none !important;
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
  background: rgb(0 0 0 / 0%) !important;
}

.mat-form-field-subscript-wrapper {
  margin-top: 1.2rem;
}

.mat-menu-panel {
  max-width: fit-content !important;
  min-width: fit-content !important;
}

.mat-select-arrow {
  color: var(--primary-color);
}

.listing__count-chips-sort {
  padding: var(--entire-page-padding);

  .mat-form-field-appearance-standard .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-appearance-standard.mat-form-field-has-label .mat-select:not(.mat-select-empty) .mat-select-arrow-wrapper {
    transform: none;
  }
}

.contact_button {
  .mat-button-wrapper {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .content {
    @include text-line-clamp(1);
    text-align: center;
  }
}

.contact {
  .mat-button-wrapper {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .content {
    @include text-line-clamp(3);
    text-align: center;
  }
}

.mat-menu-panel {
  max-width: fit-content;
}