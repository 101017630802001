.appointment_popup {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0;
    padding-bottom: 1.2rem;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: white;
  }
  .mat-input-element {
    background-color: white;
  }
  .mat-form-field-appearance-outline .mat-form-field-prefix,
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0.3rem;
  }
  .mat-form-field-infix {
    border-block: 0.4rem solid transparent;
  }
  input.mat-input-element,
  .mat-select {
    margin-top: 0.4rem;
  }
  .dateTime {
    color: black !important;
  }
  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: none;
  }
  .mat-select-arrow {
    border: none !important;
    padding: 0;
    transform: none;
    color: var(--primary-color);
    border-left: 2.4rem solid transparent !important;
    border-right: none !important;
    border-top: 2.4rem solid transparent !important;
    border-image-source: url("../../../assets/images/keyboard_arrow_down_black_24dp.svg") !important;
    border-image-repeat: stretch !important;
  }
  .textarea {
    .mat-form-field-appearance-outline .mat-form-field-flex {
      height: 8.813rem;
    }
  }
}

.popup__tabs {
  .interests {
    .mat-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }
  .mat-tab-body {
    height: 100%;
  }
  .mat-tab-body-wrapper {
    height: 100%;
  }
  .mat-tab-label-content {
    gap: 1rem;
  }
  .mat-tab-label {
    opacity: 1;
  }
  .mat-sort-header-content {
    color: black;
  }
  .mat-dialog-container {
    background-color: white !important;
  }
}
