.locations-card{
      .mat-card-header-text {
        margin: 0 !important;
      }
      .mat-card-header-text{
        display: none!important;
    }
      .mat-card-footer {
    margin: 0 !important;
    }
}
