.adib_wrapper {
  .fifth-section {
    .mat-tab-label .mat-tab-label-content {
      gap: 1rem;
    }
    .mat-tab-label {
      border: 0.1rem #dddddd solid;
      font-weight: 700;
    }
    .mat-tab-body {
      width: 90vw;
    }
    .mat-tab-label:first-child {
      border-radius: 1rem 0rem 0rem 1rem;
      [dir="rtl"] & {
        border-radius: 0rem 1rem 1rem 0rem;
      }
    }
    .mat-tab-label:last-child {
      border-radius: 0rem 1rem 1rem 0rem;
      [dir="rtl"] & {
        border-radius: 1rem 0rem 0rem 1rem;
      }
    }

    mat-icon {
      width: 2rem;
      // height: 2rem;
      color: var(--primary-color);
    }
  }
  // .projects-swiper {
  //   .swiper-slide {
  //     flex-basis: fit-content;
  //   }
  // }
  .monthly,
  .finance {
    .form {
      mat-select {
        border: 1px solid var(--input-border-color);
        border-radius: 0.5rem;
        padding: 0.5rem 1rem;
        height: 4.8rem;
        width: 100%;
        flex: 0.58;
        text-align: center;
        .mat-select-value {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .mat-select-trigger,
        .mat-select-value {
          height: 100%;
        }
      }
    }
  }
}
.mat-dialog-container {
  overflow: unset !important;
  background-color: #fff;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: #fff;
}
mat-slider {
  .mat-slider-thumb {
    transform: scale(1) !important;
    width: 1.6rem;
    height: 1.6rem;
    border-width: 0.4rem !important;
    bottom: -0.8rem;
  }
}
.mat-slider.mat-primary .mat-slider-thumb {
  background-color: #fff;
  border: 0.4rem solid #e41117;
}
.mat-select-group {
  position: relative;
  .mat-arrow-icon {
    position: absolute;
    top: 50%;
    [dir="rtl"] & {
      left: 0;
      transform: translate(+50%, -50%);
    }
    [dir="ltr"] & {
      right: 0;
      transform: translate(-50%, -50%);
    }
    color: #e7131a;
    pointer-events: none;
  }
  .mat-select-arrow {
    border: none;
  }
}
.form {
  width: 100%;
  margin-top: 2.4rem;

  @media only screen and (max-width: 768px) {
    margin-top: 0;
  }
  .unit-type {
    ._opacity {
      opacity: 0.54;
      font-weight: bold;
    }
  }
  &-group {
    margin-top: 2.5rem;
  }
  &-label {
    opacity: 0.54;
    font-weight: bold;
  }
  &-input {
    border: 1px solid var(--input-border-color);
    border-radius: 0.5rem;
    height: 4.8rem;
    padding: 0.5rem 1rem;
    width: 100%;
    flex: 0.58;
  }
  &-icon-group {
    position: relative;
    input {
      [dir="rtl"] & {
        padding: 0.5rem 1rem 0.5rem 4.2rem !important;
      }
      [dir="ltr"] & {
        padding: 0.5rem 4.2rem 0.5rem 1rem !important;
      }
    }
  }
  &-icon {
    font-weight: bold;
    color: #e7131a;
    width: 4rem;
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
    [dir="rtl"] & {
      left: 0;
    }
    [dir="ltr"] & {
      right: 0;
    }
  }
}
.finance,
.monthly {
  .title {
    font-size: 2.4rem;
  }
  .sub-title {
    opacity: 87%;
    text-align: end;
  }
  .section {
    position: relative;
    display: flex;
    justify-content: space-between;
    &-header {
      .section-right {
        position: absolute;
        [dir="rtl"] & {
          left: 0;
        }
        [dir="ltr"] & {
          right: 0;
        }
        @media only screen and (max-width: 768px) {
          position: static;
        }
      }
    }
    &-left {
      width: 67%;
    }
    &-right {
      width: 30%;
      display: flex;
      flex-direction: column;
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      &-media {
        flex-direction: column-reverse !important;
      }
      &-left {
        width: 100%;
      }
      &-right {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
      .sub-title {
        text-align: start;
      }
    }
  }
}
.adib {
  .form {
    .ng-invalid.ng-touched {
      border: 1px solid red;
    }
    .ng-touched.ng-dirty.ng-valid,
    .ng-untouched,
    .ng-valid {
      border: 1px solid #dddddd;
      border-radius: 0.5rem;
    }
    mat-error {
      text-align: start;
    }
  }
}
.adib_wrapper {
  .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    display: none;
  }

  .fourth-section {
    .left {
      .mat-tab-header {
        border-bottom: none;
      }

      .mat-tab-labels {
        gap: 2rem;
      }

      .mat-tab-label {
        border: 2px solid var(--unnamed-color-00a7e8) !important;
        background: #ffffff 0% 0% no-repeat padding-box !important;
        border-radius: 5px !important;
        min-width: 115px !important;
        opacity: 1;
        color: rgb(0 0 0 / 50%);
      }

      .mat-tab-label-active {
        border: 2px solid #00a7e8 !important;
        color: black;
      }

      .mat-tab-body-content {
        display: flex;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 5px;
        padding: 2rem;
        gap: 2rem;
      }

      mat-tab-group {
        margin-bottom: 3rem;
      }

      .mat-tab-body.mat-tab-body-active {
        overflow-y: hidden;
      }

      .mat-tab-body {
        margin-top: 3rem;
      }
    }
  }

  .fifth-section {
    .mat-tab-header {
      border-bottom: none;
    }
  }

  .fourth-section-2 {
    .left {
      .mat-tab-header {
        border-bottom: none;
      }

      .mat-tab-labels {
        gap: 2rem;
      }

      .mat-tab-label {
        border: 2px solid var(--unnamed-color-00a7e8) !important;
        background: #ffffff 0% 0% no-repeat padding-box !important;
        border-radius: 5px !important;
        min-width: 115px !important;
        opacity: 1;
        color: rgb(0 0 0 / 50%);
      }

      .mat-tab-label-active {
        border: 2px solid #00a7e8 !important;
        color: black;
      }

      .mat-tab-body-content {
        display: flex;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 5px;
        padding: 2rem;
        gap: 2rem;
      }

      mat-tab-group {
        margin-bottom: 3rem;
      }

      .mat-tab-body.mat-tab-body-active {
        overflow-y: hidden;
      }

      .mat-tab-body {
        margin-top: 3rem;
      }
    }
  }
}
.adib {
  .mat-tab-label-container {
    overflow-x: auto;
  }
}

.mat-slider-horizontal .mat-slider-wrapper {
  margin-inline-end: 1rem;
}
