$flagsImagePath: "~intl-tel-input/build/img/";
.iti__flag {
  background-image: url("#{$flagsImagePath}/flags.png");
}
.iti {
  width: 95%;
  .iti__country-list {
    text-align: start !important;
    &:lang(ar) {
      direction: ltr;
      text-align: end !important;
      left: -12px;
    }
  }
  .iti__flag-container {
    .iti__selected-flag {
      &:lang(ar) {
        direction: ltr;
      }
    }
  }
}
.salesman-iti {
  .iti {
    .iti__country-list {
      width: 27.1rem;
    }
  }
}
@media screen and (max-width: 800px) {
  .iti .iti__country-list {
    width: 41.3vw !important;
    margin: 15rem 0 0 19rem;
  }
}
