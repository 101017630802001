.contact-salesman-dialog-container {
  width: 62rem;
  @media screen and (max-width: 650px) {
    margin-bottom: 0;
    margin-top: 2.6rem;
    max-width: 97vw !important;
    width: 43rem;
  }
  .mat-dialog-container {
    padding: 0;
    border-radius: 26px !important;
    background-color: #ffffff;
    overflow: hidden;
    max-height: none !important;
  }
  .toggle-btn {
    .mat-slide-toggle-label {
      font-size: 1.6rem;
      font-weight: bold;
      flex-direction: row-reverse;
      justify-content: space-between;
      gap: 1rem;
      .mat-slide-toggle-content {
        display: flex;
      }
    }
  }
}
.zoom {
  .mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .isConnecting,
  .isStart {
    path {
      fill: black !important;
    }
  }
}
.options .mat-radio-label {
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;
  justify-content: center;
  .mat-radio-label-content {
    padding: 0 !important;
    font-size: 1.4rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  .mat-radio-container {
    display: none;
  }
}

.end-meeting {
  .grey-icon {
    .mat-icon-button {
      .mat-button-wrapper {
        color: grey;
      }
    }
  }
  .red-icon {
    .mat-icon-button {
      .mat-button-wrapper {
        color: red;
      }
    }
  }
}
