@import "../../../styles/abstract/abstract";

.card_details_popups {
  position: relative !important;
  height: 90vh !important;
  min-width: 85vw;
  .mat-dialog-container {
    padding: 0px;
    border-radius: var(--input-border-radius);
    overflow: visible !important;
  }

  .mat-card-content {
    height: 270px !important;
    overflow-y: scroll;
    @include customize-scroll();
  }

  .mat-card-header-text {
    display: none;
  }
}
