@import "src/styles/abstract/abstract";
.compare_payment_plan {
  .mat-select {
    border: 0.1rem solid var(--input-border-color);
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    height: 4.8rem;
    width: 100%;
    flex: 0.65;
    text-align: center;
    @media (max-width: 770px) {
      width: 80%;
    }
  }

  .mat-select-min-line {
    font-size: var(--fs-20);
    font-weight: 900;
    color: var(--primary-color);
  }

  .mat-select-arrow {
    border-left: 2.4rem solid transparent !important;
    border-right: none !important;
    border-top: 2.4rem solid transparent !important;
    border-image-source: url("../../../assets/images/keyboard_arrow_down_black_24dp.svg") !important;
    border-image-repeat: stretch !important;
  }

  @include lt-sm {
    .mat-select-min-line {
      font-size: var(--fs-16);
    }
  }
}
