.customers {
  .mat-tab-label-container .mat-tab-list {
    overflow: hidden;
  }

  .mat-tab-nav-bar,
  .mat-tab-header {
    border: none !important;
  }

  .mat-tab-labels {
    gap: 2rem;
    margin-bottom: 15px;
  }

  .mat-tab-label {
    opacity: 1;
  }

  // .mat-tab-label {
  //   background: #ffffff 0% 0% no-repeat padding-box;
  //   border-radius: 10px;
  //   opacity: 0.54;
  //   margin-bottom: 2rem;
  // }

  .mat-tab-label-active {
    background: var(--primary-color);
    box-shadow: 0px 10px 15px #0000000d;
    border-radius: 10px;
    color: #fff;
    font-weight: 800;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    border-width: 0.5rem !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 3rem 3rem 0 !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 3rem 0 0 3rem !important;
  }

  .primary {
    color: var(--primary-color);
  }

  .mat-option-text {
    color: black;
  }

  .hidden {
    .mat-tab-label:nth-child(3) {
      display: none;
    }
  }
}

.opp_expectValue {
  sup {
    color: var(--primary-color);
  }
}

.add-lead {
  width: 100%;

  .mat-form-field-wrapper {
    margin: 0 !important;
  }

  .mat-form-field-flex {
    height: 5.3rem;
    align-items: center;
  }
}