.custom-slider .ngx-slider .ngx-slider-bar {
  background: grey;
  height: 2px;
}
.custom-slider .ngx-slider .ngx-slider-selection {
  background: var(--primary-color);
}
.custom-slider .ngx-slider .ngx-slider-pointer {
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50px;
  top: -8px;
  bottom: 0;
  background-color: var(--primary-color);
}
.custom-slider .ngx-slider .ngx-slider-bubble {
  bottom: 14px;
  top: 15px;
  color: black;
  font-size: 14px;
}
.custom-slider .ngx-slider .ngx-slider-limit {
  font-weight: bold;
  color: var(--primary-color);
}
.custom-slider .ngx-slider .ngx-slider-tick {
  width: 1px;
  height: 10px;
  margin-left: 4px;
  border-radius: 0;
  background: var(--primary-color-faded);
  top: -1px;
}
.custom-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: var(--primary-color);
}
.ngx-pagination {
  text-align: center;
  font-size: 18px;
  .current {
    background-color: var(--primary-color) !important;
  }
}
