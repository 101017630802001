:root {
  --fetured-color: #fbb03b;
  --fetured-background: #f2220d;
  --white-color: #ffffff;
  --black-color: #000000;
  --color-semigray: #eeeeee;
  --background-color: #f5f5f5;
  --background-color-lighter: #fafafa;
  --primary-color: #e7131a;
  --primary-color-faded: hsl(358, 85%, 99%);
  --secondary-color: #fbb03b;
  --secondary-color-faded: hsl(358, 85%, 99%);
  --input-background-color: white;
  --input-border-color: #b1b1b1;
  --primary-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  --secondary-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  --line-color: #eeeeee;
  --tui-clear-hover: rgba(265, 61, 86, 0.6); // translucent dark fill hover
  --tui-clear-active: rgba(265, 61, 86, 0.8); // translucent dark fill pressed
  --tui-text-03: rgba(265, 61, 86, 1);
  --color-success: #009f3a;
  --border-color: #e4e4e4;
  --dark-blue-color: #002f69;
  --grey-color: #dddddd;
  --light-grey-color: #eeeeee;
  --grey-lighter: #F9F9F9F9;
}