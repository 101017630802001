.flag-currency {
  .mat-select {
    font-weight: 700 !important;

    @media screen and (max-width: 1279px) {

      font-size: 2rem;
    }
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: transparent !important;
  }

  .mat-form-field-underline {
    display: none !important;
  }

  .mat-form-field-infix {
    width: min-content !important;

    border-top: none !important;
  }

  .mat-form-field-appearance-fill .mat-form-field-infix {
    padding: 0 !important;
  }

  .mat-select-arrow-wrapper {
    height: auto !important;
    margin-left: 5px !important;
  }

  .mat-select-arrow {
    border: solid black !important;
    border-width: 0 2px 2px 0 !important;
    display: inline-block !important;
    margin-top: 2px !important;
    padding: 3px !important;
    transform: rotate(45deg) !important;
  }

  .mat-form-field.mat-focused .mat-select-arrow {
    border-width: 0 0 2px 2px !important;
    transform: translateY(5px) rotate(135deg) !important;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0 !important;
  }

  .mat-select-panel .ng-animating {
    visibility: hidden !important;
  }

  @media (min-width: 1279px) {
    .mat-select {
      font-weight: 400 !important;
    }

    .mat-form-field-appearance-fill .mat-form-field-infix {
      border-top: 0.84375em solid transparent !important;
    }
  }
}

.rounded-flag {
  border-radius: 50% 50% !important;
}