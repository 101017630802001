:root {
  --header-height: 128px;
  --header-smaller-height: 85px;
  --header-z-index: 999;
  --search-z-index: 5;
  --entire-page-padding: 0px auto ;//129px  on (1920px wireframe)
  --mobile-page-padding: 0px 12.9rem;
  --primary-border-radius: 5px;
  --secondary-border-radius: 10px;
  --button-border-radius: 50px;
  --input-border-radius: 10px;
  --chips-wrapper-height-open-px: 40px;
  --imgs-border-radius: 16px;
}

@media screen and (min-width: 2560px) {
  //max-width: 4095px
  :root {
    --entire-page-padding: 0px 35rem;
  }
}

@media screen and (max-width: 2559px) {
  :root {
    --entire-page-padding: 0px 20rem;
  }
}

@media screen and (max-width: 1920px) {
  :root {
    --entire-page-padding: 0px 10rem; //12.9=>129px
  }
}

@media screen and (max-width: 1700px) {
  :root {
    --entire-page-padding: 0px 6rem !important;
    --mobile-page-padding: 0px 6rem !important;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --entire-page-padding: 0px 4rem !important;
    --mobile-page-padding: 0px 4rem !important;
  }
}

@media screen and (max-width: 820px) {
  :root {
    --entire-page-padding: 0px 2.5rem !important;
    --mobile-page-padding: 0px 2.5rem !important;
  }
}

@media screen and (max-width: 769px) {
  :root {
    --entire-page-padding: 0px 1rem !important;
    --mobile-page-padding: 0px 1rem !important;
  }
}

//done
@media screen and (max-width: 480px) {
  :root {
    --entire-page-padding: 0px 1rem !important;
    --mobile-page-padding: 0px 2rem !important;
  }
}
