html[dir="rtl"] {
  .material-icons {
    font-family: "Material Icons" !important;
  }
}

.bank_transfer {
  .mat-datepicker-toggle-default-icon {
    fill: var(--primary-color);
  }
}
.adib-white {
  svg * {
    fill: white;
  }
}
.discount {
  path {
    fill: black;
  }
}
