.salesmanProfileWrapper {
  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mat-header-row {
    background-color: #eeeeee;
    color: black;
    width: 100%;
    overflow-x: auto;
  }

  .mat-header-cell {
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media screen and (max-width: 800px) {
      font-size: 1.4rem;
    }
  }

  .mat-cell {
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media screen and (max-width: 800px) {
      font-size: 1.2rem;
      flex-direction: column !important;
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    top: auto;
  }

  .mat-paginator {
    border-radius: 1rem;

    @media screen and (max-width: 959px) {
      height: 8rem !important;
      width: 146rem;
      background-color: white;
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border: none;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border: none;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border: none;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    margin-top: -0.7rem;
  }

  input.mat-input-element {
    margin-top: -1.0625rem;
    height: 100%;
  }

  .mat-progress-bar-buffer {
    background-color: #EEEEEE;
  }

  .mat-progress-bar .mat-progress-bar-buffer {
    border-radius: 1rem;
    width: 90%;
  }

  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0;
    margin-top: -0.25em;
    position: relative;
    padding-left: 2rem;
  }
}

.user-popup {
  .mat-dialog-container {
    padding: 0;
    border-radius: 0.65rem;
  }

  .mat-tab-label,
  .mat-tab-link {
    color: black;
  }

  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 1.3rem 0;
    border-top: 0.5rem solid transparent;
  }

  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    display: none;
  }

  .mat-tab-header {
    border: none;
  }

}