/*------------------- Start of  Leaflet Overrides ------------------*/
.leaflet-control-attribution {
  display: none;
}
.marker-cluster-small,
.marker-cluster-small div,
.marker-cluster-medium,
.marker-cluster-medium div,
.marker-cluster-large,
.marker-cluster-large div {
  background-color: var(--primary-color);
  span {
    font-size: var(--fs-18);
    color: var(--white-color);
  }
}
.marker-cluster {
  border-radius: 12px;
}
.leaflet-container a {
  color: var(--primary-color);
}
.leaflet-zoom-box {
  border: 2px dotted var(--primary-color);
  background: rgba(255, 255, 255, 0.5);
}
/*------------------- End of  Leaflet Overrides ------------------*/
