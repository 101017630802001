//imports for swiper library it self seprated from our styles
// @import "~swiper/css";
// @import "~swiper/css/autoplay";
// @import "~swiper/css/navigation";
// @import "~swiper/css/pagination";
// @import "~swiper/css/thumbs";
/////////////////////////////////////////////////////////////////////
//any overrides styles will be after this lines
//khaled: i took all styles from current file and set it into  _unsortedStyles then any page needs to change or override styles we will create _PageName.scss file the import it after unsortedStyles.scss
@import "src/styles/abstract/media-queries";

.swiper {
  width: 100%;
  // height: 100%;
}

.launch-swiper,
.hotel-prelaunch-swiper,
.launch-cards-container {
  .swiper-slide {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    width: max-content;

    @media (max-width: 1024px) {
      flex-basis: 46%;
    }

    @media (max-width: 812px) {
      width: 100%;
    }

    @media (max-width: 640px) {
      width: 90%;
      flex-basis: 80% !important;
    }

    @media (max-width: 375px) {
      width: 98%;
    }
  }
}

.launch-cards-container {
  .swiper-slide {
    flex-basis: 50% !important;

    @media (max-width: 1024px) {
      flex-basis: 56% !important;
    }

    @media (max-width: 640px) {
      flex-basis: 80% !important;
    }
  }
}

.launch-cards-adminstartive-container {
  .swiper-slide {
    flex-basis: 49% !important;

    @media (max-width: 1366px) {
      flex-basis: 55% !important;
    }

    @media (max-width: 1024px) {
      flex-basis: 78% !important;
    }

    @media (max-width: 1024px) {
      flex-basis: 100% !important;
    }

    @media (max-width: 800px) {
      flex-basis: 50% !important;
    }

    @media (max-width: 568px) {
      flex-basis: 100% !important;
    }

    @media (max-width: 500px) {
      flex-basis: 85% !important;
    }
  }
}

.launch-swiper,
.events-swiper {
  .swiper-slide {
    flex-basis: 49%;
  }
}

.hotel-prelaunch-swiper {
  .swiper-slide {
    display: block;
    flex-basis: 50%;

    @media (max-width: 500px) {
      flex-basis: 95%;
    }
  }
}

.events-swiper {
  .swiper-slide {
    @media (max-width: 1024px) {
      width: 50%;
    }

    @media (max-width: 414px) {
      width: 80%;
    }

    @media (max-width: 414px) {
      flex-basis: auto;
    }
  }
}

.developer-offers-section-swiper {
  .swiper-slide {
    width: 50%;

    @media (max-width: 800px) {
      width: 60%;
    }

    @media (max-width: 568px) {
      width: 75%;
    }
  }
}

.hotels-container {
  .swiper-slide {
    flex-basis: 32.7%;
    min-height: 40rem;

    @media (max-width: 812px) {
      flex-basis: 50%;
    }

    @media (max-width: 500px) {
      flex-basis: 94%;
    }
  }
}

.hotel-launch-swiper {
  .swiper-slide {
    flex-basis: 100%;

    @media (max-width: 1279px) {
      flex-basis: 56% !important;
    }

    @media (max-width: 640px) {
      flex-basis: 80% !important;
    }
  }
}

.adminstrative-prelaunch-swiper {
  .swiper-slide {
    flex-basis: 33%;

    @media (max-width: 1024px) {
      flex-basis: 36%;
    }

    @media (max-width: 812px) {
      flex-basis: 50%;
    }

    @media (max-width: 667px) {
      flex-basis: 68%;
    }

    @media (max-width: 500px) {
      flex-basis: 92%;
    }
  }
}

.developer-launch,
.developer-blog-swipper {
  .swiper-slide {
    flex-basis: 90%;

    @media (max-width: 1366px) {
      flex-basis: 87%;
    }

    @media (max-width: 1024px) {
      flex-basis: 56%;
    }

    @media (max-width: 812px) {
      flex-basis: 73%;
    }

    @media (max-width: 667px) {
      flex-basis: 85%;
    }

    @media (height: 1366px) {
      flex-basis: 60%;
    }
  }
}

.commerial-launches {
  .swiper-slide {
    @media (max-width: 1310px) {
      width: fit-content !important;
    }
  }
}

.developer-blog-swipper {
  .swiper-slide {
    flex-basis: 24%;

    @media (max-width: 1024px) {
      flex-basis: 40%;
    }

    @media (max-width: 812px) {
      flex-basis: 40%;
    }

    @media (max-width: 667px) {
      flex-basis: 51%;
    }

    @media (max-width: 414px) {
      flex-basis: 85%;
    }
  }
}

.prelaunch-swiper {
  .swiper-slide {
    flex-basis: 29.9% !important;

    @media (max-width: 1024px) {
      flex-basis: 50% !important;
    }

    @media (max-width: 640px) {
      flex-basis: 90% !important;
    }

    @media (max-width: 375px) {
      flex-basis: 97% !important;
    }
  }

  .swiper-slide-active {
    flex-basis: 41% !important;

    @media (max-width: 1024px) {
      flex-basis: 50% !important;
    }

    @media (max-width: 640px) {
      flex-basis: 90% !important;
    }

    @media (max-width: 375px) {
      flex-basis: 97% !important;
    }
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: black;
  font-size: 1.125rem;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin-top: 20px;
  text-align: center;
  font-size: 7px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
  bottom: 0px;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  top: 133px;
  width: 100%;
}

.swiper-pagination-bullet-active {
  color: var(--white-color);
  background: #007aff;
}

.swiper-button-prev:after {
  font-size: 1.125rem;
  font-weight: 900;
}

.swiper-button-next:after {
  font-size: 1.125rem;
  font-weight: 900;
}

.swiper-thumbnails {
  justify-content: space-between;
  width: 100%;
  margin-inline-start: 7px;

  .swiper-slide {
    height: max-content;
    box-sizing: border-box;
    flex-shrink: 1;
    text-align: center;
    font-size: 1.125rem;

    img {
      width: 100%;
      max-width: 95px !important;
      height: auto;
      aspect-ratio: 1/1;
      object-fit: cover;
      box-shadow: var(--primary-shadow);
      border-radius: 15px;
      border: none !important;
      filter: brightness(0.5);
      transition: transform 0.16s ease-in-out;
    }
  }

  .swiper-slide-thumb-active {
    img {
      filter: brightness(1);
      border: 4px var(--primary-color) solid;
      transform: scale(1.05);
    }

    .last-image:before {
      filter: grayscale(1);
    }
  }
}

.swiper-chatbot {
  .swiper-slide {
    @media screen and (max-width: 450px) {
      margin-inline-start: 3rem;
    }
  }

  .swiper-wrapper {
    display: flex !important;
  }
}

.container__cards,
.area-cards {
  .swiper-slide {
    @media (max-width: 600px) {
      width: 35% !important;
    }
  }
}

.HotelSectionLocationSwipper {
  .swiper-slide {
    flex-basis: 19.3%;

    @media (max-width: 500px) {
      flex-basis: 15.4%;
      width: auto !important;
    }

    @media (max-width: 1920px) {
      flex-basis: 19.3%;
    }

    @media (max-width: 1365px) {
      flex-basis: 18.4%;
    }

    @media (max-width: 1090px) {
      flex-basis: 18.2%;
    }
  }
}

.swiper-img {
  [dir="rtl"] & {
    transform: scaleX(-1);
  }
}

// .swiper__action {
//   mat-icon {
//     [dir="ltr"] &.arrow-left {
//       transform: rotate(180deg);
//     }

//     [dir="rtl"] &.arrow-right {
//       transform: rotate(180deg);
//     }
//   }
// }
