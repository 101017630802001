.icon-white {

  svg,
  path {
    fill: white !important;
  }
}

.icon-lightgrey {

  svg,
  path {
    fill: #b1b1b1 !important;
  }
}

.icon-secondary {

  svg,
  path {
    fill: #fbb03b !important;
  }
}

.icon-primary {

  svg,
  path {
    fill: #e7131a !important;
  }
}

.icon-black {

  svg,
  path {
    fill: black !important;
  }
}