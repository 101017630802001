.SharedImagesComponentWrapper {
  .container {
    .selected-swiper-thumbnail-viewer {
      min-height: 50vh;
      width: 100%;
    }
    &__landing {
      &__image-wrapper {
        .mySwiper2 {
          min-height: 50vh;

          @media (max-width: 959px) {
            height: 30rem;
          }
        }
      }
    }
  }
}
