.compoundPageWrapper {
  .modelViews {
    .mat-tab-nav-bar,
    .mat-tab-header {
      border-bottom: none;
    }
    .compound-tabs {
      margin-top: 4rem;
      .mat-tab-labels {
        display: flex;
        .mat-ink-bar {
          visibility: hidden;
        }
      }
      .mat-tab-label {
        width: 150px;
        height: 58px;
        min-width: none;
        width: auto;
        justify-content: center;
        padding: 0;
        background: #f5f5f5;
        border: 1px solid #dddddd;
        border-radius: 10px;
        opacity: 1;
        background: none;
        font-weight: 900;
        font-size: 1.4rem;
        &:last-child {
          border-radius: 0px 10px 10px 0;
        }
        &:first-child {
          border-radius: 10px 0px 0px 10px;
        }
        &:only-child {
          border-radius: 15px;
        }
        .tab-icon {
          color: var(--primary-color);
          width: 18px;
          height: 18px;
        }
        @media (max-width: 599px) {
          min-width: 14rem;
          h4 {
            font-size: 2rem;
          }
        }
        &-active {
          background: #ffffff;
        }
      }
      .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
        display: none;
      }
      .mat-tab-label .mat-tab-label-content {
        white-space: pre-wrap;
        align-items: baseline;
        @media (max-width: 800px) {
          flex-direction: column;
        }
      }
      .tab-title {
        @media (max-width: 800px) {
          font-size: 1rem;
        }
      }
      .mat-tab-label {
        .mat-tab-label-content {
          @media (max-width: 959px) {
            margin: 0 1.25rem;
          }
        }
      }
    }
    .mat-radio-ripple {
      display: none;
    }
  }
  .gridViews {
    .mat-tab-label-container {
      background-color: var(--background-color);
    }
    .compound-tabs {
      margin-top: 4rem;
      .mat-tab-labels {
        display: flex;
      }
      .mat-tab-label {
        height: 12.5rem;
        min-width: none;
        border-top-right-radius: 10px;
        width: auto;
        flex: 1;
        justify-content: center;
        @media (max-width: 599px) {
          min-width: 14rem;
          h4 {
            font-size: 2rem;
          }
        }
      }
      .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
        display: none;
      }
      .mat-tab-label .mat-tab-label-content {
        white-space: pre-wrap;
        align-items: flex-start;
        @media (max-width: 800px) {
          flex-direction: column;
        }
      }
      .tab-title {
        @media (max-width: 800px) {
          font-size: 1rem;
        }
      }
      .mat-tab-label {
        &:first-child {
          .mat-tab-label-content {
            margin: 0;
            margin-inline-start: 1.5vw;
            margin-inline-end: 1.5rem;
          }
        }
        .mat-tab-label-content {
          @media (max-width: 959px) {
            margin: 0 1.25rem;
          }
        }
      }
    }
    .mat-ripple .mat-tab-label .mat-focus-indicator .mat-tab-label-active {
      flex: 1;
    }
    .mat-radio-ripple {
      display: none;
    }
  }
  @media screen and (max-width: 480px) {
    .paragraph {
      display: none;
    }
  }
}
.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: #fff0 !important;
}
html[dir="rtl"],
html[lang="ar"] {
  .compoundPageWrapper .modelViews .compound-tabs {
    .mat-tab-label {
      width: 150px;
      height: 58px;
      min-width: none;
      width: auto;
      justify-content: center;
      padding: 0;
      background: #f5f5f5;
      border: 1px solid #dddddd;
      border-radius: 10px;
      opacity: 1;
      background: none;
      font-weight: 900;
      font-size: 1.4rem;
      &:first-child {
        border-radius: 0px 10px 10px 0;
      }
      &:last-child {
        border-radius: 10px 0px 0px 10px;
      }
      &:only-child {
        border-radius: 15px;
      }
    }
  }
}
.compoundPageWrapper {
  .selected-swiper-thumbnail-viewer {
    height: 420px !important;
  }
  .swiper-backface-hidden .swiper-slide {
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}
@media screen and (max-width: 480px) {
  .compoundPageWrapper {
    .selected-swiper-thumbnail-viewer {
      height: 225px !important;
      width: 97% !important;
    }
  }
}
.modelViews {
  .swiper-wrapper {
    padding-bottom: 2rem;
  }
}
