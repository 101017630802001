.custom-payment-plan {
  .mat-dialog-container {
    overflow: visible !important;
    border-radius: 10px !important;
    @media (max-width: 959px) {
      height: 90vh;
      width: 90vw;
    }
  }
  .iti {
    width: 100%;
  }
}
