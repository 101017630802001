.offers {
  .swiper-wrapper {
    justify-content: space-between;
  }
}

.header__wrapper__offers {
  p {
    @media (max-width: 800px) {
      font-size: 4rem;
    }
  }
}