@mixin stepperHeaderBefore($backGroundColor, $height, $width, $position, $bottom, $left) {
  background-color: $backGroundColor !important;
  height: $height !important;
  width: $width !important;
  position: $position !important;
  bottom: $bottom !important;
  left: $left !important;
  content: "" !important;
}
@mixin steppIcon($height, $width, $border, $boxShadow, $marginRight) {
  height: $height !important;
  width: $width !important;
  border: $border;
  box-shadow: $boxShadow;
  margin-right: $marginRight;
}
%stepper-header-container {
  position: absolute;
  width: 70%;
  margin-top: -158px;
  z-index: 1;
  left: 0;
  margin-left: 0;
  padding-left: 0;
}

.reservationPageWrapper {
  position: relative;
  .mat-step-header:hover {
    background-color: transparent !important;
  }
  .mat-step-header {
    background-color: transparent !important;
  }
  .mat-checkbox-layout .mat-checkbox-label {
    white-space: break-spaces;
  }

  .mat-step-icon-selected {
    box-shadow: 0px 10px 15px #0000001a !important;
  }

  .mat-step-header .mat-step-label {
    @media (max-width: 670px) {
      display: none !important;
    }
  }

  .mat-step-label.mat-step-label-active.mat-step-label-selected {
    display: block !important;
  }

  .mat-step-header {
    overflow: visible !important;
    align-items: flex-start !important;
    flex: 1;
  }

  .mat-horizontal-stepper-header-container:before {
    @include stepperHeaderBefore(#b1b1b1, 0.1rem, 69%, absolute, 5.3rem, 0.8rem);
    @media (max-width: 800px) {
      bottom: 6rem !important;
    }
    @media (max-width: 600px) {
      width: 79% !important;
    }
  }

  mat-step-header[aria-selected="true"] {
    z-index: 99999999;
    width: 100%;
    align-items: flex-start !important;

    @media (max-width: 600px) {
      flex: 2;
    }
    &:last-child {
      flex: 0.8 !important;
    }
  }

  mat-step-header[aria-selected="false"] {
    z-index: 99999999;
    width: 100%;
    flex: 1;
    align-items: flex-start !important;
  }

  .mat-step-header .mat-step-header-ripple {
    display: none;
  }

  .mat-stepper-horizontal-line {
    display: none;
  }

  .mat-horizontal-stepper-header-container {
    @extend %stepper-header-container;
    transform: translateX(30%);
    @media (max-width: 959px) {
      width: 100%;
      transform: translateX(10%);
    }
    @media (max-width: 800px) {
      position: absolute;
      margin-top: -112px;
      transform: translateX(2%);
    }
  }

  .mat-horizontal-stepper-header {
    display: flex;
    flex-direction: column;
    height: 8rem !important;
    padding: 0 !important;
  }

  .mat-step-label,
  .mat-step-label-active,
  .mat-step-label-selected {
    font-weight: 900;
    margin-top: 1rem;
    text-align: center;

    @media (max-width: 500px) {
      font-size: 1.4rem;
    }
  }

  .mat-step-icon,
  .mat-step-icon-state-number,
  .mat-step-icon-selected {
    @include steppIcon(5rem, 5rem, 0.1rem solid #b1b1b1, var(--primary-shadow), 0);
    @media (max-width: 800px) {
      @include steppIcon(3.6rem, 3.6rem, 0.1rem solid #b1b1b1, var(--primary-shadow), 0);
    }
  }

  .mat-step-icon {
    background: white !important;
    color: var(--primary-color) !important;
  }

  .mat-step-icon-selected {
    background-color: var(--primary-color) !important;
    color: white !important;
    border: none;
  }
}

.reservationPageWrapperAr {
  .mat-horizontal-stepper-header-container:before {
    @include stepperHeaderBefore(#b1b1b1, 0.1rem, 69%, absolute, 5.3rem, unset);
    @media (max-width: 800px) {
      bottom: 6rem !important;
    }
    @media (max-width: 600px) {
      width: 79% !important;
    }
  }

  .mat-horizontal-stepper-header-container {
    @extend %stepper-header-container;
    transform: translateX(33%);
    @media (max-width: 959px) {
      width: 100%;
      transform: translateX(-7%);
    }
    @media (max-width: 800px) {
      position: absolute;
      margin-top: -112px;
      transform: translateX(-4%);
    }
  }
}

.faweryref {
  .mat-horizontal-stepper-header-container:before {
    @include stepperHeaderBefore(#b1b1b1, 0.1rem, 79%, absolute, 5.3rem, unset);
    @media (max-width: 800px) {
      bottom: 6rem !important;
    }
    @media (max-width: 600px) {
      width: 79% !important;
    }
  }
}
