.home-tabs {
  margin-top: 4rem;

  .mat-tab-labels {
    display: flex;
    opacity: 1;
    background: #f5f5f5;
  }

  .mat-tab-label {
    height: 13rem;
    min-width: none;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: auto;
    flex: 1;
    opacity: 1;
    justify-content: flex-start;
    @media (max-width: 600px) {
      min-width: 14rem;
      h4 {
        font-size: 2rem;
      }
    }
  }
  .mat-tab-label-active {
    @media (max-width: 600px) {
      background-color: white;
      opacity: 1;
    }
  }
  .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    display: none;
  }
  .mat-tab-label .mat-tab-label-content {
    white-space: pre-wrap;
    align-items: flex-start;
    @media (max-width: 800px) {
      flex-direction: column;
    }
  }
  .tab-title {
    @media (max-width: 800px) {
      font-size: 1rem;
    }
  }
  .mat-tab-label {
    &:first-child {
      .mat-tab-label-content {
        margin: 0;
        margin-inline-start: 1.5vw;
        margin-inline-end: 1.5rem;
      }
    }
    .mat-tab-label-content {
      @media (max-width: 959px) {
        margin: 0 1.25rem t;
      }
    }
  }
}
.mat-radio-button {
  margin-bottom: 0.313rem;
}
.grey-bg {
  &_content {
    .searchNav {
      margin: 0;
    }
  }
}
.container {
  &_card {
    &_content {
      &_content-bg {
        .ul-col2,
        .ul-col1 {
          ul {
            li {
              @include text-line-clamp(1);
            }
            margin-top: 0.375rem;
            max-height: 6.45ch;
            @media (max-width: 800px) {
              grid-template-columns: 1fr;
            }
          }
          ul.ar {
            max-height: 7.45ch;
          }
        }
      }
    }
  }
}
.mat-elevation-z24 {
  .ul-col2,
  .ul-col1 {
    ul {
      margin-top: 0.375rem;
      max-height: 6.45ch;
      li {
        @include text-line-clamp(1);
      }
      @media (max-width: 800px) {
        grid-template-columns: 1fr;
      }
    }
  }
}
mat-card {
  box-shadow: none;
  border-radius: var(--secondary-border-radius);
}
.models-wrapper {
  .mat-mini-fab .mat-button-wrapper {
    line-height: 1.375rem;
  }
}
.mat-ink-bar {
  visibility: visible;
  left: 0;
  width: 34.625rem;
  height: 7.5rem;
  z-index: -1;
  border-radius: 1.625rem 1.625rem 0 0;
  background-color: white;
}
.mat-tab-label:focus,
.mat-tab-label-active,
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  opacity: 1;
  background-color: white;
}
.homepage {
  .mat-tab-header {
    position: sticky !important;
    z-index: 999;
    top: 0;
    background-color: white;
    @media (max-width: 1024px) {
      background-color: #f5f5f5;
      border: none;
    }
  }
}
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent;
}
.mat-drawer {
  box-shadow: none;
}
.top {
  .mat-tab-header {
    top: 4rem;
    height: 12.5rem;
    @media screen and (max-width: 1279px) {
      top: 3rem;
    }
  }
}
.text-icon {
  path {
    fill: inherit;
  }
}
.ins-icon {
  path {
    fill: white;
  }
}
.search-zoom {
  #Path_1948 {
    fill: #4a8cff;
  }
  #Path_1949 {
    fill: #23b7ec;
  }
}
.whatsapp-search {
  #Path_1941,
  #Path_1942 {
    fill: #22b13a;
  }
}
.listMenu {
  max-width: fit-content;
  .mat-menu-content:not(:empty) {
    max-width: fit-content;
    display: flex;
    align-items: center;
    gap: 3rem;
    height: 100%;
    padding: 2rem;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
}
.special-request {
  position: relative;
  .mat-dialog-container {
    position: relative;
    background-color: white;
    overflow: inherit;
    max-height: none;
  }
}
.ul-col1 {
  list-style-type: none;
  padding-inline-start: 0;
  list-style-position: inside;
  ul {
    color: #737373;
    list-style-type: none;
    padding-inline-start: 0;
    list-style-position: inside;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    li {
      list-style-type: none;
      padding-inline-start: 0;
      list-style-position: inside;
    }
  }
}
.ul-col2 {
  ul {
    color: #737373;
    display: grid;
    list-style-type: none;
    padding-inline-start: 0;
    list-style-position: inside;
    white-space: nowrap;
    width: 80%;
  }
}
.homepage {
  .mat-tab-list {
    transform: none !important;
    background: #fff;
  }
}
.loading-logo {
  display: flex;
  flex-direction: column;
  width: 13rem;
  img {
    width: 13rem;
    height: 5.5rem;
    margin-bottom: 3rem;
  }
  .bar {
    border: 1px solid var(--primary-color);
    width: 0;
    animation: identifier 2s;
    background-color: var(--primary-color);
  }
}
@keyframes identifier {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
.mat-tab-label-container {
  @media (max-width: 900px) {
    overflow-x: auto !important;
  }
  .mat-tab-list {
    transform: none !important;
  }
}
