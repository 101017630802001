.developerLandingWrapper {
  // .container {
  //   @media (min-width: 1024px) {
  //     .selected-swiper-thumbnail-viewer {
  //       height: 50rem;
  //     }
  //   }
  //   @media (min-width: 500px) {
  //     .swiper-wrapper {
  //       width: max-content;
  //     }
  //   }
  //   &__developer-landing-container {
  //     &__large-img-wrapper {
  //       .swiper-thumbnails {
  //         display: flex;
  //         justify-content: flex-end;
  //         right: 0rem !important;
  //         margin: 0rem !important;
  //       }
  //     }
  //   }
  //}
}

.developerOverviewWrapper {
  .container {
    .about-developer {
      @media (min-width: 1366px) {
        .overview-container__text-wrapper {
          right: -60% !important;
        }
      }
    }
    .row {
      @media (width: 1366px) {
        .text-wrapper {
          right: -17%;
        }
      }
    }
  }
  .arabic {
    .about-developer {
      @media (min-width: 1366px) {
        .text-wrapper {
          left: -60% !important;
        }
      }
    }
    .row {
      @media (width: 1366px) {
        .text-wrapper {
          left: -17% !important;
        }
      }
    }
  }
}

.blog-card {
  &__card-header {
    .mat-card-header-text {
      display: none !important;
    }
  }
}
.developerOverviewWrapper {
  picture {
    @media (max-width: 800px) {
      margin: 0 !important;
      width: 100%;
      height: 50rem;
    }
  }
}
.about-developer {
  picture {
    @media (max-width: 800px) {
      margin: 0 !important;
      height: 40rem;
      width: 100%;
    }
  }
}
.terms_and_conditions_popup {
  min-height: 50rem;
  width: 67rem;
  @media (max-width: 959px) {
    height: 50vh;
    width: 80%;
  }
}
