.salesman-general {
  .mat-select,
  .mat-date-range-input {
    height: 4.8rem;
    border: 0.1rem solid #dddddd;
    border-radius: 0.5rem;
    padding: 1rem;
    text-align: center;
    background-color: #fff;
  }
  .mat-date-range-input {
    width: 114%;
    padding-inline-start: 1.6rem;
    .mat-date-range-input-inner {
      text-align: start;
      padding-top: 3px;
      font-size: 1.6rem;
      font-weight: bold;
    }
  }

  .mat-form-field-suffix .mat-icon-button {
    padding-inline-end: 3.3rem;
    color: #000;
  }

  .mat-select-arrow {
    border-left: 24px solid transparent !important;
    border-right: none !important;
    border-top: 24px solid transparent !important;
    border-image-source: url("../../../assets/images/keyboard_arrow_down_black_24dp.svg") !important;
    border-image-repeat: stretch !important;
  }

  .mat-form-field-appearance-legacy,
  .mat-form-field-underline {
    background-color: transparent !important;
    .mat-form-field-label {
      padding: 1rem;
      font-weight: bold;
    }
  }

  .mat-form-field-infix {
    border-top: 0 !important ;
    padding: 0 !important;
    width: 240px !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }

  .mat-form-field.mat-focused,
  .mat-form-field-ripple,
  .mat-form-field.mat-focused,
  .mat-form-field-ripple.mat-accent {
    background-color: transparent !important;
  }

  .mat-select-value,
  .mat-option-text {
    text-align: start;
    padding-inline-start: 1rem;
    padding-top: 3px;
    font-size: 1.6rem;
    font-weight: bold;
  }

  .mat-icon-button {
    color: #eb3a41 !important;
  }

  .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
    display: none;
  }
}

.sales-date,
.end-meeting {
  mat-form-field {
    height: 4.8rem;
    border: 0.1rem solid #dddddd;
    border-radius: 0.5rem;
    background-color: #fff !important;
    padding-inline-start: 1rem;
    padding-block: 1rem;
  }
}

.end-meeting {
  .mat-form-field {
    width: 21rem;
  }
  .mat-form-field-suffix .mat-icon-button {
    padding-inline-end: 3.3rem;
    color: #000;
  }

  .mat-form-field-appearance-legacy,
  .mat-form-field-underline {
    background-color: transparent !important;
  }

  .mat-form-field.mat-focused,
  .mat-form-field-ripple,
  .mat-form-field.mat-focused,
  .mat-form-field-ripple.mat-accent {
    background-color: transparent !important;
  }

  .mat-icon-button {
    color: #eb3a41 !important;
  }

  .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
    display: none;
  }
}

.meeting-popup {
  max-height: 88vh !important;
  .mat-dialog-container {
    height: auto;
  }
}
