@mixin customize-scroll($w: 1rem, $h: 1rem, $border: 0.375rem) {
  @media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      width: $w;
      height: $h;
      border-radius: 6.25rem;
      background-clip: padding-box;
      border: $border solid transparent;
      z-index: 10000000;
    }

    &::-webkit-scrollbar {
      background-color: #eeeeee;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--tui-clear-hover);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--tui-clear-active);
    }

    &::-webkit-scrollbar-thumb:active {
      background-color: var(--tui-text-03);
    }
  }
}

@mixin text-line-clamp($line: 2) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@mixin flex($align: center, $justify: center, $flow: row, $wrap: nowrap) {
  display: flex;
  flex-flow: $flow;
  align-items: $align;
  justify-content: $justify;
  flex-wrap: $wrap;
}
@mixin dimensions($height, $width, $minHeight, $maxHeight, $minWidth, $maxWidth) {
  height: $height;
  width: $width;
  max-width: $maxWidth;
  min-width: $minWidth;
  max-height: $maxHeight;
  min-height: $minHeight;
}
