.mat-dialog-container {
  background: var(--background-color);
  position: relative;
  max-height: 44vw;
  overflow-y: auto;
  padding: 2rem;
  @import "src/styles/abstract/mixins";

  @include customize-scroll();
}

.cdk-overlay-pane.image-viewer {
  width: 100vw;
  background: #000c;
}

.image-viewer {
  max-width: 100vw !important;
  height: 100%;

  .swiper {
    overflow: visible;
  }

  .mat-dialog-container {
    background: transparent;
    padding: 0;
    overflow: inherit;
    box-shadow: none;
  }

  .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    top: unset !important;
    width: 100%;
    bottom: -24px;
  }

  .swiper-pagination-bullet-active {
    background: white;
  }

  .swiper-slide-active {
    z-index: 20;
    overflow: auto;
  }
}

.image_container {
  @include lt-sm {
    .mySwiper1 {
      .swiper-slide {
        max-width: 90px !important;
        height: 90px !important;
      }
    }
  }

  @include lt-xs {
    .mySwiper1 {
      .swiper-slide {
        margin-right: 6px !important;
        max-width: 75px !important;
        height: 75px !important;
      }
    }
  }
}