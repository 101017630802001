.projectsPageWrapper {
  .mat-checkbox-label {
    font-size: 1.4rem;
    font-weight: 800;
  }
}
@media screen and (max-width: 480px) {
  .mat-radio-outer-circle {
    width: 16px;
    height: 16px;
  }
}
