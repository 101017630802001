@mixin xs {
  @media only screen and (max-width: 599px) {
    @content;
  }
}

@mixin md {
  @media only screen and (min-width: 960px) and (max-width: 1279px) {
    @content;
  }
}

@mixin sm {
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    @content;
  }
}

@mixin xl {
  @media only screen and (min-width: 1920px) and (max-width: 5000px) {
    @content;
  }
}

@mixin lg {
  @media only screen and (min-width: 1280px) and (max-width: 1919px) {
    @content;
  }
}

@mixin lt-xs {
  @media only screen and (max-width: 320px) {
    @content;
  }
}

@mixin lt-sm {
  @media only screen and (max-width: 599px) {
    @content;
  }
}

@mixin lt-md {
  @media only screen and (max-width: 959px) {
    @content;
  }
}

@mixin lt-lg {
  @media only screen and (max-width: 1279px) {
    @content;
  }
}

@mixin lt-xl {
  @media only screen and (max-width: 1919px) {
    @content;
  }
}

@mixin gt-xs {
  @media only screen and (min-width: 600px) {
    @content;
  }
}

@mixin gt-sm {
  @media only screen and (min-width: 960px) {
    @content;
  }
}

@mixin gt-md {
  @media only screen and (min-width: 1280px) {
    @content;
  }
}

@mixin gt-lg {
  @media only screen and (min-width: 1920px) {
    @content;
  }
}
