.payment-plan-details-popup {
  min-width: 85vw;
  position: relative !important;
  overflow: visible !important;

  .mat-dialog-container {
    padding: 2.5rem;
    border-radius: var(--input-border-radius);
    position: relative !important;
    overflow: visible !important;

    @media (max-width: 600px) {
      padding: 2.5rem 0.5rem;
    }
  }

  .mat-dialog-title {
    margin-bottom: 0 !important;
    border-radius: 10px;
  }

  .mat-dialog-content {
    min-height: 50vh;
    max-height: 75vh;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
}

.mobile-payment-plan-details-popup {
  position: relative !important;
  overflow: visible !important;
  height: 95vh;
  width: 100% !important;
  max-width: 100vw !important;
  padding: 0 1rem;

  .mat-dialog-container {
    padding: 0;
    border-radius: var(--input-border-radius);
    position: relative !important;
    overflow: visible !important;
    padding-block: 0;
    background: transparent !important;
    box-shadow: none !important;
  }

  .mat-dialog-title {
    margin-bottom: 0 !important;
    border-radius: 10px;
  }

  .mat-dialog-content {
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
}

.paymentPlan_dropdown {}

.payment__plan-container {
  .mat-radio-button {
    .mat-radio-label {
      .mat-radio-label-content {
        font-size: var(--fs-16);
        opacity: 0.54 !important;
      }
    }

    &.mat-radio-checked {
      .mat-radio-label {
        .mat-radio-label-content {
          opacity: 1 !important;
        }
      }
    }
  }

}

.reload {
  @include flex();
  position: absolute;
  inset: 0;
  z-index: 5;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  border-radius: 15px;
  cursor: pointer;

  mat-icon {
    width: 100px;
    height: 100px;
    font-size: 100px;
  }
}