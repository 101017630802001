@use "./styles/components";
@import "./styles/abstract/abstract";
@import "./styles/components/components";

/*------------------- General Styling ------------------*/
.custom-scroll {

  @include customize-scroll();
}

*::selection {
  background: var(--primary-color);
  color: var(--white-color);
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type="checkbox"],
input[type="radio"],
input[type="range"],
progress {
  accent-color: var(--primary-color);
}

a,
button {
  cursor: pointer;
}

strong {
  color: var(--primary-color);
}

.mat-typography h1,
.mat-typography h3,
.mat-typography h4,
.mat-typography h5,
.mat-typography h6,
.mat-typography p,
.mat-typography .mat-display-1,
.mat-typography .mat-display-2,
.mat-typography .mat-display-3,
.mat-typography .mat-display-4,
.mat-typography .mat-display-1,
.mat-typography .mat-subheading-1,
.mat-typography .mat-subheading-2,
p,
figure {
  margin: 0rem;
}

.cdk-overlay-connected-position-bounding-box {
  margin-top: 10px;
}

/*------------------- End of General Styling  ------------------*/

/*------------------- Html and Body styling ------------------*/

html>body {
  height: 100%;
  // height: 100svh;
  width: 100vw;
}

html {
  height: 100%;
  font-size: 62.5% !important;

  @media screen and (min-width: 2559px) {
    //to start from 2060px and above
    font-size: 74.5% !important;
  }

  @media screen and (min-width: 4095px) {
    //to start from 4096px and above
    font-size: 72.5% !important;
  }

  @media screen and (max-width: 1024px) {
    font-size: 56.25% !important;
  }

  @media screen and (max-width: 480px) {
    font-size: 50% !important;
  }
}

@-ms-viewport {
  width: device-width;
}

body {
  height: 100%;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1.6rem !important;
  font-family: "Circular", "Almarai";
  min-width: auto !important;
  min-height: auto !important;
  overflow: hidden;
}

html[dir="ltr"] {
  .text-end-ar {
    text-align: end;
  }
}

html[dir="rtl"] {
  .text-start-en {
    text-align: end;
  }
}

// html[dir="rtl"] {
//   * {
//     letter-spacing: unset !important;
//     font-family: "Almarai" !important;
//     direction: rtl;
//   }
// }

// html[dir="ltr"] {
//   * {
//     font-family: "Circular" !important;
//     direction: ltr;
//   }
// }

/*------------------- End Of Html and Body styling ------------------*/

/*------------------- Helper Classes ------------------*/

.w-100,
.max-w-100 {
  width: 100%;
}

.h-100,
.max-h-100 {
  height: 100%;
}

.rd-5 {
  border-radius: 5px;
}

.rd-10 {
  border-radius: 10px;
}

.object-cover {
  object-fit: cover;
}

.cursorPointer {
  cursor: pointer;
}

.card__spinner {
  @include flex();
  position: absolute;
  inset: 0;
  z-index: 5;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.divider {
  height: 1px;
  background: #dddddd;
  border-radius: 10px;
  width: 100%;
  margin-block: 1rem;
}

.section__title {
  color: #5a5a5a;
  opacity: 1;
  font-size: var(--fs-24);
  font-weight: 900;
  margin-block: 1rem;

  @include lt-md {
    font-size: var(--fs-20);
  }
}

.show__more {
  color: var(--primary-color);
  opacity: 0.87;
  font-size: var(--fs-16);
  margin-bottom: 0;
  font-weight: 900;
  text-decoration: underline;
  cursor: pointer;
}

.close__popup__button {
  position: absolute;
  right: -59px;
  top: 0;
  cursor: pointer;
  border-radius: 5px;

  .button {
    @include flex();
    padding: 1rem;
    width: 48px;
    height: 48px;
    border: 0;
    border-radius: 10px;
    cursor: pointer;

    @include lt-md {
      width: 36px;
      height: 36px;
    }
  }

  @include lt-md {
    right: 0.5rem;
    top: 0.5rem;
  }
}

.d-none {
  display: none !important;
}

.position-relative {
  position: relative;
}

.title-btns {
  margin-top: 30px;

  .swiper-btn {
    width: auto;

    a,
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: inline-flex;

      mat-icon {
        width: 3rem;
        height: 3rem;
        font-size: 3rem;
      }
    }

    mat-icon {
      font-size: 1.5rem;
      width: 30px;
      height: 30px;
      color: #000;
    }
  }
}

/*------------------- End of  Helper Classes ------------------*/

/*------------------- Browser Support ------------------*/
input {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    height: 2.6rem;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    height: 2.6rem;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    height: 2.6rem;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    height: 2.6rem;
  }
}

/*------------------- End of Browser Support ------------------*/

/*------------------- Panel Classes ------------------*/

.dialog_radius-1 {
  .mat-dialog-container {
    border-radius: 1rem;
  }
}

.mediaPopup {
  mat-dialog-container {
    background-color: white;
    border-radius: 10rem;
    padding: 0 3rem !important;
  }
}

.card_map_popups {
  height: 80vh;
  max-height: 95vh;
  min-width: 85vw;
  position: relative !important;
  overflow: visible !important;

  .mat-dialog-container {
    padding: 0.8rem;
    border-radius: var(--input-border-radius);
    overflow: visible;
  }
}

/*------------------- End Of Panel Classes ------------------*/

.profile-btn-arrow {}

.arrow_navigate {
  [lang='ar'] & {
    transform: rotate(180deg);
  }
}

.social-icon * {
  fill: #fff;
  opacity: 1;
}

// spinner for add new prooerty form

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.226);
  z-index: 9999;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}