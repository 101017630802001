.prelaunchFeatures2Wrapper {
  .card {
    max-width: 50rem !important;
    height: 29vh !important;
    margin: 0rem !important;
    @media screen and (max-width: 480px) {
      width: 100% !important;
    }
  }
  .card__transparentImage {
    right: auto;
    left: -3rem;
  }
  .card__label img {
    display: none !important;
  }
  .card__label {
    bottom: 0 !important;
    right: 0 !important;
    top: auto !important;
    left: auto !important;
    border-top-left-radius: 1rem;
    padding-inline-start: 2rem;
    padding-top: 2rem;
  }
}

.prelaunch_popup {
  min-width: 122.8rem;
  min-height: 48rem;
  @media (max-width: 959px) {
    height: 50%;
    width: 80%;
  }
}
