.mat-button {
  font-size: var(--fs-16) !important;
}

.mat-stroked-button,
.mat-flat-button {
  min-width: 14.4rem !important;
  padding: 10px 20px !important;
  line-height: normal !important;
  border-radius: 50px !important;
  width: fit-content;

  @media (max-width: 414px) {
    padding: 10px 20px !important;
  }
}

.mat-stroked-button {
  border: 1px solid var(--primary-color) !important;
  font-weight: 800 !important;
}

.offer-btn {
  background-color: #fbb03b !important;
  color: black !important;
}

.gbtn {
  outline: none;
  min-width: 11rem;
  display: inline-block;
  height: 4rem;
  padding: 0 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  background-color: transparent;
  color: black;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
  text-decoration: none;

  &.pill {
    border-radius: 2.4rem;
  }

  &-flat {
    &.primary {
      background-color: var(--primary-color);
      color: var(--white-color);

      mat-icon {
        color: var(--white-color);
      }

      .icon {

        svg,
        path {
          fill: var(--white-color);
        }
      }
    }

    &.secondary {
      background-color: var(--secondary-color);
    }

    &.dark-blue {
      background-color: var(--dark-blue-color);
      color: var(--white-color);
    }

    &.grey {
      background-color: var(--grey-color);
    }

    &.white {
      box-shadow: var(--primary-shadow);
      background-color: var(--white-color);
    }
  }

  &-stroked {
    &.primary {
      border: 0.2rem solid var(--primary-color);
    }

    &.secondary {
      border: 0.2rem solid var(--secondary-color);
    }

    &.dark-blue {
      border: 0.2rem solid var(--dark-blue-color);
    }

    &.grey {
      border: 0.2rem solid var(--grey-color);
    }

    &.white {
      border: 0.2rem solid var(--white-color);
    }
  }

  .icon {

    svg,
    path {
      fill: var(--primary-color);
    }
  }

  mat-icon {
    color: var(--primary-color);
    font-size: 2rem;
    height: 2rem;
    width: 2rem;
    margin-inline-end: 0.8rem;
    vertical-align: bottom;
  }

  &-icon {
    min-width: unset;
    background-color: var(--white-color);
    border: 1px solid var(--light-grey-color);
    border-radius: 1rem;
    width: 4rem;
    padding: 0;

    mat-icon {
      height: 2.4rem;
      width: 2.4rem;
      margin: 0;
      text-align: center;
      vertical-align: middle;
      color: var(--primary-color);

      svg,
      path {
        fill: var(--primary-color);
      }
    }

    &.hover:hover {
      border-color: var(--primary-color);
    }

    &.circle {
      border-radius: 50%;
    }

    &.sm-box {
      height: 4rem;
      width: 4rem;
    }

    &.box {
      background-color: #fafafa;
    }
  }

  &-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.disabled {
    background: #aba8a8;
    cursor: not-allowed;
  }
}

.profile-container {

  .navBtn {
    display: flex;
    align-items: center;
    border: unset;
    height: max(6rem, 60px);
    width: 100%;
    min-width: max-content;
    border-radius: 1rem;
    padding: 0 2rem;
    text-transform: capitalize;
    background-color: transparent;

    img,
    mat-icon {
      width: 2.4rem;
      max-height: 2.2rem;
      color: var(--primary-color);
      margin-inline-end: 1.2rem;
    }

    @media (max-width: 480px) {
      position: relative;
      border-radius: 0px;
      margin-bottom: 10px;
      background-color: var(--white-color);

      &:after {
        content: "";
        margin-inline-start: auto;
        display: block;
        border-left: 2px solid #000;
        border-top: 2px solid #000;
        width: 9px;
        height: 9px;

        [dir="rtl"] & {
          transform: rotate(320deg);
        }

        [dir="ltr"] & {
          transform: rotate(140deg);
        }
      }

      span {
        padding-inline-start: 15px;
      }

      img {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}